$(document).ready(function() {
  const formCheckTerms = document.getElementById("curriculo_aceite_termos");
  const formSubmitBtn = document.getElementById("curriculoSubimtFormButton");

  if (formCheckTerms) {
    terms_checker();

    $("input[name='curriculo[aceite_termos]']").on("change", function() {
      terms_checker();
    });
  }

  function terms_checker() {
    if (formCheckTerms.checked == true) {
      formSubmitBtn.disabled = false;
    } else {
      formSubmitBtn.disabled = true;
    }
  }
});
